button {
    padding: 12px 25px;
    border-radius: 0px;
    background-color: #1a1b1f;
    -webkit-transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
    transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
    color: #fff;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 2px;
    text-transform: uppercase;
    border: none;
}

button:hover {
    background-color: #777777;
}

.disabled {
    background-color: #777777;
    cursor: no-drop !important;
}