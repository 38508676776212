Modal {
    z-index: 3;
}

.modal-backdrop {
    z-index: 2;
}

.tree-button-wrapper {
    display: flex;
    justify-content: center;
}

.modal-content {
    border-radius: 0px;
    border: 0;
    background-color: rgba(255, 255, 255);
    /* background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(219, 219, 219) 0%); */
}

.form-control,.form-select {
    border-radius: 0px;
}

.upload-input {
    display:none;
}

.upload-image {
    cursor: pointer;
    width: 100%;
    text-align: center;
    font-weight: bold;
    padding: 10px 0;

    color: black;
    border: 1px solid black;
}

.upload-image:hover {
    background-color: rgb(230, 255, 242);
}

.progressBar {
    margin: 10px 0;
    border-radius: 0px;
}

.metamask-form {
    display: flex;
}

.metamask-btn {
    padding: 0;
    background-color: transparent;
    border: 0;
}

.metamask-btn:hover {
    background-color: transparent;
}

.metamask-img {
    height: 30px;
}

.form-unit {
    display: flex;
}

.unit-dropdown {
    width: 30%;
    margin-left: 10px;
}

.locate-button {
    background-color: transparent;
    color: black;
    border: 0;
    padding: 0;
    margin-bottom: 0.5rem;
}

.locate-button:hover {
    background-color: transparent;
    color: black;
}

.cords-form-div {
    display: flex;
    justify-content: space-between;
}

.fieldError {
    color: red;
}

.underMessage {
    color: grey;
    font-size: small;
}