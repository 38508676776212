.claim-section {
	border: rgb(233, 233, 233) 1px solid;
	padding: 20px 20px;
	max-width: 500px;
	margin: 0 auto;
	box-sizing: border-box;
}

.claim-submit {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}
