.cc-cta {
    padding-right: 80px;
    padding-left: 80px;
    background-color: #f4f4f4;
    margin: 50px 30px;
}

.container {
    width: 100%;
    max-width: 1140px;
    margin-top: 100px;
    margin-right: auto;
    margin-left: auto;
}

.cta-wrap {
    display: flex;
    padding-top: 110px;
    padding-bottom: 120px;
    justify-content: center;
    text-align: center;
}

.cta-text {
    width: 90%;
    margin-right: auto;
    margin-bottom: 35px;
    margin-left: auto;
}

.heading-jumbo-small {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 26px;
    line-height: 50px;
    font-weight: 400;
    text-transform: none;
}

.paragraph-bigger {
    margin-bottom: 10px;
    opacity: 0.6;
    font-size: 20px;
    line-height: 34px;
    font-weight: 400;
}