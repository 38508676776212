.spinner {
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.loader {
    width: 54px;
    height: 54px;
    border: 6px solid rgb(54, 54, 54);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 0.6s linear infinite;
    margin: auto auto;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 