.email-alert {
    text-align: left;
    width: 80%;
    padding: 10px 10px 0 20px;
    position: absolute;
    top: 100px;
    left: 10%;
    font-size: 14px;
    z-index: 1;
    border-radius: 0px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
  }
  
  .email-head {
    font-size: 22px;
  }