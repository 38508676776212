.dashboard {
  display: grid;
  margin: 0 30px;

  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.profile-panel,
.tree-panel,
.map-panel {
  margin: 10px;
  border: rgb(233, 233, 233) 1px solid;
  border-radius: 0px;
}

.profile-panel {
  grid-row-start: 1;
  grid-column-start: 1;

  grid-row-end: 2;
  grid-column-end: 2;
}

.tree-panel {
  grid-row-start: 1;
  grid-column-start: 2;

  grid-row-end: 2;
  grid-column-end: 4;
}

.map-panel {
  grid-row-start: 2;
  grid-column-start: 1;

  grid-row-end: 2;
  grid-column-end: 4;
}

@media only screen and (max-width: 660px) {
  .dashboard {
    display: block;
  }
}
