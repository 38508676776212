.picture-div,
.profile-edit,
.profile-information-text {
  display: flex;
  justify-content: center;
}

.picture-div,
.profile-edit,
.profile-information {
  margin: 20px;
}

.profile-picture {
  min-height: 250px;
  min-width: 250px;
  border: 1px solid black;
}

.profile-information {
  text-align: center;
}

.text-decoration {
    margin: 10px 0 0 0;
}