#admin-panel {
    margin: 0 5%;
    text-align: center;
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.500);
}

.admin-heading {
    display: flex;
    justify-content: center;
    padding-top: 1%;
}

.admin-tree-block {
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.admin-image-container {
    text-align: center;
}

.admin-button-container {
    padding: 15px 30px;
}

.admin-notree-text {
    padding: 0 0 1% 0;
}

@media screen and (max-width: 1024px) {
    .admin-tree-block {
        display: block;
        margin: 0 auto;
    }

    .admin-tree-details {
        text-align: center;
        list-style-type: none;
    }

    .admin-button-container {
        display: flex;
        justify-content: center;
    }
}