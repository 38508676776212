.nav-container {
    display: flex;
    justify-content: space-between;
    padding: 20px 50px;
}

.nav-links {
    margin: 0 auto;
}

.nav-link {
    opacity: 1;
    color: #1a1b1f;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin: 0 20px;
    font-size: 14px;
}

.on-canvas {
    display: none;
}

@media only screen and (max-width: 660px) {
    .nav-container {
        padding: 20px 50px;
    }
    .nav-links {
        padding-bottom: 10%;
    }
    .logged-in-text {
        margin: 0 -15px;
    }
    .off-canvas {
        display: none;
    }
    .on-canvas {
        display: block;
    }
}