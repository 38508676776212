#qr-panel {
    margin: 1%;
    text-align: center;
    backdrop-filter: blur(5px);
    background: rgba(255, 255, 255, 0.5);

    border-radius: 0px;
    box-shadow: 0 -20px 30px rgba(0, 0, 0, 0.15);
    height: 100%;
}

.qr-heading {
    display: flex;
    justify-content: center;
    padding-top: 1%;
}

.qr-details {
    display: grid;
    margin: 1%;
 
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    
    gap: 0px;
    height: 100%;
}

.qr-code {
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 0px;
    width: 90%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); 
}

.tree-details {
    margin: 2% 0 0 2%;
    text-align: left;
}

.tree-map {
    margin-top: 5%;
    margin-right: 5%;
}

@media only screen and (max-width: 660px) {
    .qr-details {
        display: block;
    }

    .tree-details {
        text-align: center;
    }

    .tree-map {
        margin-right: 0%;
    }
}