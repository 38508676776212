* {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
}

body {
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;
    text-align: center;
    min-height: 100vh;
}

::-webkit-scrollbar {
    background-color: rgb(255, 255, 255);
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgb(204, 204, 204);
}
