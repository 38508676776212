section {
    margin: 0 30px;
}

.intro-header {
    background-image: url("../assets/sectionBackground.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;
    display: flex;
    height: 620px;
    margin-bottom: 100px;
    justify-content: center;
    align-items: center;
}

.intro-content {
    margin-bottom: 20px;
    width: 70%;
    max-width: 1140px;
    text-align: center;
}

.intro-text {
    width: 80%;
    margin-right: auto;
    margin-bottom: 35px;
    margin-left: auto;
    box-sizing: border-box;
}

.intro-heading {
    width: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #000;
    font-size: 74px;
    line-height: 80px;
    text-align: center;
    text-transform: none;
}

.intro-paragraph {
    opacity: 0.5;
    color: #000;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 34px;
    font-weight: 400;
}

.intro-button {
    background-color: #fff;
    color: #000;
    padding: 16px 35px;
    font-size: 14px;
    line-height: 26px;
    max-width: 100%;
    display: inline-block;
}

.intro-button:hover {
    background-color: #e9e9e9;
}

.main-login {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.container {
    width: 100%;
    max-width: 1140px;
    margin-top: 100px;
    margin-right: auto;
    margin-left: auto;
}

.moto-wrap {
    width: 80%;
    margin-right: auto;
    margin-bottom: 85px;
    margin-left: auto;
    text-align: center;
}

.label {
    opacity: 0.6;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.moto-heading {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 26px;
    line-height: 50px;
    font-weight: 400;
    text-transform: none;
}

.divider {
    height: 1px;
    background-color: #eee;
}

.sequestered-data {
    display: flex;
}

.lbs {
    padding-top: 50px;
}

.projection-section {
    display: flex;
}

.calculated-data {
    min-width: 250px;
}

@media screen and (max-width: 1024px) {
    .intro-header {
        display: block;
    }

    .content {
        display: block;
    }

    .intro-heading {
        font-size: 46px;
    }

    .intro-content {
        text-align: center;
        width: 100%;
    }

    .projection-section {
        display: block;
    }
}
