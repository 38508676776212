.nftree-container {
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 1fr;
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;
    justify-content: center;
    align-content: center;
}

.nftreeGrid {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nftreeCard {
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%);
    border-radius: 0px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); 
    margin: 10px;
}

.claimedTree {
    text-align: center;
    margin-top: 20px;
}

.treeImg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}

.qrcode {
    margin: 5%;
    border-radius: 0px;
    width: 90%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); 
}

.nftreeCard-buttons {
    display: flex;
    justify-content: space-between;
}

.nftree-delete-button {
    width: 50px;
    border-radius: 0px;
}

.delete-popover {
    border-radius: 0px;
    border: 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); 
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
}

.delete-popover-heading {
    margin-top: 5px;
    margin-bottom: -10px;
    border: 0;
    background-color: transparent;
}

.nftree-delete-button-popover {
    border-radius: 0px;
}

.no-nftree {
    text-align: left;
}

.purchase-button, .approval, .rejection {
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
    padding-left: 2rem;
    padding-right: 2rem;

    width: 100%;

    margin-top: 15px;
    border-radius: 0px;
    border: 0;
    font-size: .875rem;
    background-color: rgb(54, 54, 54);
}

.checkmark {
    margin-left: 5px;
    width: 15px;
}

.rejection {
    background-color: #dc3545;
}

.rejection:hover {
    cursor: not-allowed;
    background-color: #dc3545;
}

.purchase-button:hover {
    background-color: rgb(0, 0, 0);
}

.tree-verify-spinner {
    margin-left: 10px;
    width: 12px;
    height: 12px;
}

.verification-text {
    font-style: italic;
    font-weight: 400;
    font-size: 12.5px;
    margin-bottom: -10px;
    padding: 5px 0;
    text-align: center;
}

@media only screen and (max-width: 1250px) {
    .nftree-container {
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 1fr;
    }
}

@media only screen and (max-width: 850px) {
    .nftree-container {
        grid-template-columns: 1fr; 
        grid-template-rows: 1fr;
    }
}

@media only screen and (max-width: 500px) {
    .no-nftree {
        margin-top: 5%;
        text-align: center;
    }
}

@media only screen and (max-width: 425px) {
    
}